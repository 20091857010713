.skill-progress {
  .progress {
    height: 10px;
    background-color: #e7dfdd;
    border-radius: 5px;
    .progress-bar {
      //  neon gradient #4717f6, #a239ca
      background-image: linear-gradient(270deg, #4717f6, #a239ca);
      // hover
    }
  }
}
