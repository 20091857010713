// body font family
$font-family-base: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
// navbar brand font size
$navbar-brand-font-size: 2.5rem;

$primary: #4717f6;
$secondary: #a239ca;
$light: #e7dfdd;
$dark: #0e0b16;

// import bootstrap
@import "~bootstrap/scss/bootstrap";

// add your custom styles here
// theme colors
.section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @media screen and (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 auto;
  }

  .section-title {
    text-align: center;
    margin-bottom: 4rem;
    font-size: 4rem;
    font-weight: 800;
    @media screen and (max-width: 768px) {
      font-size: 3rem;
      margin-bottom: 3rem;
    }
  }
}
