// modern card design
.certificate-card {
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem 0 #7433ff;
  padding: 2rem;
  // on mobile devices
  @media screen and (max-width: 768px) {
    margin: 1rem 0.5rem;
  }
  .card-title {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }
  .card-subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .card-text {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  .card-link {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  // animation
  transition: all 0.3s ease-in-out;
  // hover
  &:hover {
    // translate
    transform: translateY(-0.5rem);
  }
}

// .box {
//   /* Required properties */
//   position: relative;
// }

// .box:before {
//   content: "";
//   z-index: -1;
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: linear-gradient(-45deg, #4717f6 0%, #a239ca 100%);
//   transform: translate3d(8px, 20px, 0) scale(0.95);
//   filter: blur(20px);
//   opacity: var(0.7);
//   transition: opacity 0.3s;
//   border-radius: inherit;
// }

// /*
// * Prevents issues when the parent creates a
// * stacking context. (For example, using the transform
// * property )
// */
// .box::after {
//   content: "";
//   z-index: -1;
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: inherit;
//   border-radius: inherit;
// }
