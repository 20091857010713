.education-section {
  .card {
    // border #4717f6
    border: 2px solid #4717f6;
    // card float effect
    height: 100%;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 15px 20px -4px #6c757d;
    }
    // card float shadow
    box-shadow: 0px 10px 12px -4px #6c757d;
  }
}
